#content{
  margin: 0 12px;
}
.img-banner {
  border-radius: 60px;
  // width: 375px;
  height: 228px;
}
.bg-light-home {
  background: #FBFBFB;
}
.red-text {
  color: red;
}
.home-title{
  font-size: 18px;
  font-weight: 600 !important;
  margin-bottom: 1rem;
  background: -webkit-linear-gradient(0,#015aad,#00b74f);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}
.endow{
  .ant-carousel .slick-dots-bottom {
    transform: translateX(-45%);
  }
  .ant-carousel .slick-dots li button {
    width: 10px;
    height: 10px;
  }
}
.news-info__header {
  border-bottom: 1px solid #dde6e8;
  display: flex;
  justify-content: space-between;
}
.component-title{    
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}
.btn-link {
  font-weight: 500;
  color: #00b74f;
  font-size: 16px;
}
.hotline{
  font-size: 24px;
  color: #00b74f;
  text-decoration: none;
  background-color: transparent;
}
.uudai-navbar{
  width: 100%;
  margin-bottom: 1rem;
}
.card-title{
  padding: 10px 0;
  font-weight: 600;
  font-size: 16px;
}
.share-card{
  .group{
    padding-top: 20px;
  }
}
.share-img{
  border-radius: 15px;
  img{
    border-radius: 15px;
    width: 100%;
    height: 100%;
  }
}
.share-title{
  font-size: 22px;
  font-weight: 600;
  line-height: 1.5;
  margin: 12px 0;
}
.share-text{
  margin-bottom: 7px;
  color: #545758;
  font-weight: 500;
  background: #f4f7f8;
  display: block;
  display: -webkit-box;
  font-size: 16px;
  line-height: 1.7;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.new-card{
  width: 100%;
  cursor: pointer;
  .group{
    padding-top: 20px;
  }
}
.talk-to-us__content__item {
  text-align: center;
  padding: 24px 12px;
  color: #00b74f;
  font-size: 20px;
  font-weight: 500;
  display: block;
  span{
    .icon{
      display: inline-block;
      margin-right: 20px;
    }
  }
}
.talk-to-us:before {
  content: "";
  background: linear-gradient(90deg,#015aad,#00b74f);
  display: block;
  height: 1px;
  width: 100%;
}
.home {
  & > section:nth-child(odd) {
    background-color:  rgba(0, 0, 0, 0.02);
  }
  h3{
    color: #0A2851;
    font-weight: 700;
    // font-size: 18px;
    // text-align: center;
  }
}

.money-must-pay{
  border-bottom: 1px solid #9EAABB;
  padding-bottom: 4px;
  color: #0053BA;
  font-size: 16px;
  width: fit-content;
  margin: auto;
  font-weight: 600;
}

.coppy-right {
  background-color: #0053BA;
  color: #fff;
  height: 40px ;
  font-size: 12px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
}
.main-header{
  .hero-section{
    background-image: url("/banner-thai.png");
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    .hero-section-title{
      min-height: 100vh;
    }
  }
}
@media (min-width: 1024px) {
  .hero-section {
      height: 100vh;
  }
}
.mdi-note-multiple-outline::before {
  content: "\F6B8";
}
.mdi-set, .mdi:before {
  display: inline-block;
  font: normal normal normal 24px / 1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.mdi-gavel::before {
  content: "\F29B"
}

.mdi-account::before {
  content: "\F004"
}

.mdi-briefcase::before {
  content: "\F0D6"
}

.mdi-trackpad::before {
  content: "\F7F7"
}
.fa-file:before {
  content: "\f15b"
}

.fa-money-bill-wave:before {
  content: "\f53a";
}
.fa-gavel:before, .fa-legal:before {
  content: "\f0e3";
}
.fa-registered:before {
  content: "\f25d";
}
.mdi-scale-balance::before {
  content: "\F5D1";
}
.fa-check:before {
  content: "\f00c";
}
.mdi-map-marker::before {
  content: "\F34E";
}
.mdi-cellphone-text::before {
  content: "\F8D1";
}
html {
  scroll-behavior: smooth
}

body {
  font-size: 16px;
  font-family: Roboto,sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #636b72
}

ol,ul {
  margin: 0;
  padding: 0;
  list-style: none
}

a,a:active,a:focus,a:hover,a:link,a:visited {
  color: inherit;
  text-decoration: none
}

button,button:focus,input,input:focus,textarea,textarea:focus {
  outline: 0;
  resize: inherit
}

button,button:focus {
  border: none
}

section {
  overflow: hidden;
  width: 100%
}

::-moz-selection {
  background: #000;
  color: #fff
}

::selection {
  background: #000;
  color: #fff
}

.site-wrapper {
  position: relative;
  overflow: hidden
}

@media only screen and (max-width: 991.98px) {
  body,html {
      font-size:14px
  }
}

h1,h2,h3,h4,h5,h6 {
  font-family: Poppins,sans-serif;
  text-transform: capitalize;
  font-weight: 700;
  line-height: 1.5;
  color: #000;
  margin: 0
}

h1 {
  font-size: 3rem
}

h2 {
  font-size: 2.25rem
}

h3 {
  font-size: 2rem
}

h4 {
  font-size: 1.625rem
}

h5 {
  font-size: 1.375rem
}

h6 {
  font-size: 1rem
}

p {
  margin: 0;
  line-height: 28px
}

.section-padding {
  padding: 20px 0;
  position: relative
}

.section-heading {
  margin-bottom: 20px
}

.section-heading .section-title {
  position: relative;
  padding: 0 0 15px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 46px;
}

.section-heading .section-title span {
  margin-right: 10px;
  margin-left: 10px;
  color: #03c
}

.section-heading .section-title:after {
  bottom: -5px;
  width: 30px
}

.section-heading .section-title:before {
  bottom: 0;
  width: 60px
}

.section-heading .section-sub-title {
  text-align: center;
  font-size: 1rem
}

.section-badge {
  font-size: 14px;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px 20px;
  display: inline-block;
  color: #03c!important;
  background: rgba(0,51,204,.1)
}

.bg-blue-overlay:after {
  top: 0;
  left: 0;
  z-index: -1;
  opacity: .8;
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  background-position: center;
  background: #03c
}

.item-shadow {
  -webkit-transition: all .25s linear;
  transition: all .25s linear;
}


.img-jump {
  -webkit-animation: imgJump 5s linear 0s infinite normal forwards;
  animation: imgJump 5s linear 0s infinite normal forwards
}

.pt-minus-70 {
  padding-top: 70px
}

.pt-50 {
  padding-top: 50px
}

.pb-minus-70 {
  padding-bottom: 70px
}

.default-outline-btn {
  padding: 20px 40px;
  border-radius: 5px;
  display: inline-block;
  text-transform: capitalize;
  color: #fff!important;
  background: #13a874;
  -webkit-transition: all .25s linear;
  transition: all .25s linear;
}

.default-outline-btn:hover {
  color: #fff!important;
  background: #109164;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.default-btn {
  padding: 20px 40px;
  display: inline-block;
  text-transform: capitalize;
  color: #fff!important;
  background: #03c;
  -webkit-transition: all .25s linear;
  transition: all .25s linear;
  border-radius: 1rem
}

.default-btn:hover {
  color: #fff!important;
  background: #002db3;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.default-video-btn {
  display: inline-block;
  padding: 20px 25px;
  border-radius: 5px;
  margin-left: 10px;
  text-align: center;
  text-transform: capitalize;
  color: #13a874!important;
  background: #fff;
  -webkit-transition: all .25s linear;
  transition: all .25s linear;
}

.default-video-btn:hover {
  color: #fff!important;
  background: #13a874;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.scroll-top-btn {
  bottom: 0;
  opacity: 0;
  right: 10px;
  width: 48px;
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  text-align: center;
  position: fixed;
  border-radius: 5px;
  visibility: hidden;
  color: #fff!important;
  background: #13a874;
  -webkit-transition: all .25s ease;
  transition: all .25s ease;
}

.scroll-top-btn:hover {
  color: #fff!important;
  background: #109164;
}

.scroll-top-btn.active {
  opacity: 1;
  bottom: 10px;
  visibility: visible
}

@-webkit-keyframes buttonEffectRight {
  0% {
      right: -100%
  }

  100% {
      right: 100%
  }
}

@keyframes buttonEffectRight {
  0% {
      right: -100%
  }

  100% {
      right: 100%
  }
}

@-webkit-keyframes imgJump {
  0% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }

  20% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }

  41% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px)
  }

  50% {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px)
  }

  50% {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px)
  }

  80% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }

  100% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
}

@keyframes imgJump {
  0% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }

  20% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }

  41% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px)
  }

  50% {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px)
  }

  50% {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px)
  }

  80% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }

  100% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
}


@-webkit-keyframes stickyMenu {
  0% {
      margin-top: -120px;
      opacity: 0
  }

  50% {
      margin-top: -64px;
      opacity: 0
  }

  100% {
      margin-top: 0;
      opacity: 1
  }
}

@keyframes stickyMenu {
  0% {
      margin-top: -120px;
      opacity: 0
  }

  50% {
      margin-top: -64px;
      opacity: 0
  }

  100% {
      margin-top: 0;
      opacity: 1
  }
}

@-webkit-keyframes preloader {
  0% {
      top: 8px;
      height: 64px
  }

  100%,50% {
      top: 24px;
      height: 32px
  }
}

@keyframes preloader {
  0% {
      top: 8px;
      height: 64px
  }

  100%,50% {
      top: 24px;
      height: 32px
  }
}

@-webkit-keyframes dashedRotate {
  0% {
      -webkit-transform: rotate(0);
      transform: rotate(0)
  }

  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg)
  }
}

@keyframes dashedRotate {
  0% {
      -webkit-transform: rotate(0);
      transform: rotate(0)
  }

  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg)
  }
}

@-webkit-keyframes iconPulse {
  0% {
      -webkit-transform: scale(.5);
      transform: scale(.5);
      opacity: 0
  }

  50% {
      opacity: 1
  }

  100% {
      opacity: 0;
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

@keyframes iconPulse {
  0% {
      -webkit-transform: scale(.5);
      transform: scale(.5);
      opacity: 0
  }

  50% {
      opacity: 1
  }

  100% {
      opacity: 0;
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

@-webkit-keyframes spinnerLoad {
  1% {
      top: -15px
  }

  2% {
      top: 10px
  }

  5% {
      top: -20px
  }

  10% {
      left: -10px
  }

  20% {
      top: -25px
  }

  30% {
      left: 15px;
      background: #13a874
  }

  35% {
      top: 5px
  }

  45% {
      top: -5px
  }

  50% {
      top: -5px
  }

  60% {
      top: -6px
  }

  70% {
      top: 6px
  }

  80% {
      top: 6px
  }

  60% {
      top: -6px
  }
}

@keyframes spinnerLoad {
  1% {
      top: -15px
  }

  2% {
      top: 10px
  }

  5% {
      top: -20px
  }

  10% {
      left: -10px
  }

  20% {
      top: -25px
  }

  30% {
      left: 15px;
      background: #13a874
  }

  35% {
      top: 5px
  }

  45% {
      top: -5px
  }

  50% {
      top: -5px
  }

  60% {
      top: -6px
  }

  70% {
      top: 6px
  }

  80% {
      top: 6px
  }

  60% {
      top: -6px
  }
}

@-webkit-keyframes spinnerDelay {
  0%,100%,40% {
      -webkit-transform: scaleY(.4);
      transform: scaleY(.4)
  }

  20% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1)
  }
}

@keyframes spinnerDelay {
  0%,100%,40% {
      -webkit-transform: scaleY(.4);
      transform: scaleY(.4)
  }

  20% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1)
  }
}

.custom-modal {
  z-index: 4444
}

.custom-modal .modal-content {
  background: 0 0
}

.custom-modal .modal-content .modal-header {
  padding: 20px 30px;
  background: #03c;
  border-radius: 5px 5px 0 0
}

.custom-modal .modal-content .modal-header .modal-title {
  color: #fff;
  font-size: 1.1rem
}

.custom-modal .modal-content .modal-header button {
  opacity: 1
}

.custom-modal .modal-content .modal-header button span {
  font-weight: 400;
  color: #fff
}

.custom-modal .modal-content .modal-body {
  padding: 30px 30px 20px 30px;
  background: #fff;
  border-radius: 0 0 5px 5px
}

.custom-modal .modal-content .modal-body .popup-form-group {
  position: relative;
  overflow: hidden
}

.custom-modal .modal-content .modal-body .popup-form-group .popup-form-control {
  height: 45px;
  outline: 0;
  width: 100%;
  border: none;
  color: #000;
  padding: 0 20px 0 50px;
  margin-bottom: 20px;
  -webkit-transition: all .25s ease;
  transition: all .25s ease;
}

.custom-modal .modal-content .modal-body .popup-form-group .popup-form-control:focus {
  outline: 0;
  border: none;
}

.custom-modal .modal-content .modal-body .popup-form-group>textarea.popup-form-control {
  height: auto;
  padding: 18px 50px
}

.custom-modal .modal-content .modal-body .popup-form-group>span {
  position: absolute;
  top: 22px;
  left: 16px;
  font-size: 16px;
  color: #03c;
  border-radius: 0 5px 5px 0
}

.custom-modal .modal-content .modal-body .popup-form-btn {
  border: none;
  outline: 0;
  width: 100%;
  display: block;
  border-radius: 5px;
  padding: 20px 60px;
  color: #fff!important;
  background: #03c;
  -webkit-transition: all .25s linear;
  transition: all .25s linear;
}

.custom-modal .modal-content .modal-body .popup-form-btn:hover {
  color: #fff!important;
  background: #002db3;
}

.custom-modal .modal-content .modal-body .custom-control {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: auto;
  line-height: 26px;
  margin-bottom: 20px
}

.custom-modal .modal-content .modal-body .custom-control .custom-control-label {
  margin-left: 15px;
  padding-left: 10px;
  color: #000
}

.custom-modal .modal-content .modal-body .custom-control .custom-control-label:before {
  background: #fff;
  border: 1px solid #03c;
}

.custom-modal .modal-content .modal-body .custom-control .custom-control-input:checked~.custom-control-label:before {
  color: #fff;
  border-color: #03c;
  background: #03c
}

.comments-wrap .comment-item {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0,0,0,.08)
}

.comments-wrap .comment-item:last-child {
  border-bottom: 0
}

.comments-wrap .comment-item .comment-item-title {
  margin-bottom: 10px
}

.comments-wrap .comment-item img {
  border-radius: 5px;
  margin-right: 20px
}

.comments-wrap .comment-item .comment-meta {
  margin-bottom: 10px
}

.comments-wrap .comment-item .comment-meta span {
  display: inline-block;
  margin-right: 10px;
  color: #000;
  font-size: 15px
}

.comments-wrap .comment-item .comment-meta span i {
  margin-right: 10px;
  color: #03c
}

.comments-wrap .comment-item .reply-btn {
  color: #000!important;
  margin-top: 10px;
  font-weight: 500;
  display: inline-block
}

.comments-wrap .comment-item .reply-btn i {
  vertical-align: -1px;
  display: inline-block
}

.comments-wrap .sub-comment-item {
  margin-left: 120px
}

.pagination-wrap {
  margin-top: 40px
}

.pagination-wrap .pagination-link {
  width: 42px;
  height: 42px;
  line-height: 42px;
  margin-right: 5px;
  border-radius: 5px;
  text-align: center;
  background: #fff;
  display: inline-block;
  -webkit-transition: all .25s linear;
  transition: all .25s linear;
}

.pagination-wrap .pagination-link.active,.pagination-wrap .pagination-link:hover {
  color: #fff;
  background: #03c
}

.pagination-wrap .pagination-link:last-child {
  margin-right: 0
}

.preloader-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 4444;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #03c;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column
}

.preloader-wrap .preloader-inner {
  height: 100px;
  width: 100px;
  position: relative;
  margin: 0 auto
}

.preloader-inner .spinner {
  margin: auto;
  margin-top: -100px;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px
}

.preloader-inner .spinner>div {
  background-color: #fff;
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: spinnerDelay 1.2s infinite ease-in-out;
  animation: spinnerDelay 1.2s infinite ease-in-out
}

.preloader-inner .spinner .rect1 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s
}

.preloader-inner .spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s
}

.preloader-inner .spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s
}

.preloader-inner .spinner .rect4 {
  -webkit-animation-delay: -.9s;
  animation-delay: -.9s
}

.preloader-inner .spinner .rect5 {
  -webkit-animation-delay: -.8s;
  animation-delay: -.8s
}

.preloader-inner .sk-circle {
  margin: 10px auto 100px auto;
  width: 10px;
  height: 10px;
  position: relative;
  background: #fff;
  border-radius: 100%;
  left: -18px;
  -webkit-animation: spinnerLoad 1.2s infinite ease-in-out;
  animation: spinnerLoad 1.2s infinite ease-in-out
}

.header {
  padding: 10px 0;
  z-index: 3333
}

.header .ss4-phone {
  padding: 0 0 0 25%;
  position: absolute;
  font-size: 16px;
  font-weight: 600;
  color: #fff
}

.header .ss4-phone .ss4no {
  color: #ffae00
}

.header .ss4-mail {
  padding: 0 0 0 22%;
  position: absolute;
  font-size: 16px;
  font-weight: 600;
  color: #fff
}

.header .ss4-mail .ss4id {
  color: #ffae00
}

@media only screen and (max-width: 575.98px) {
  .header .ss4-phone {
      display:none
  }

  .header .ss4-phone .ss4no {
      display: none
  }

  .header .ss4-mail {
      display: none
  }

  .header .ss4-mail .ss4id {
      display: none
  }
}

.header .nav-link {
  font-size: 12px;
  font-weight: 500;
  padding: 20px 15px!important;
  text-transform: uppercase;
  letter-spacing: .6px;
  display: block;
  position: relative;
  color: #fff;
  line-height: 28px
}

.header .nav-link.active:after {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 7px;
  content: '';
  position: absolute;
  background: #13a874
}

.header .nav-link:not(.active):after {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 7px;
  z-index: -7;
  content: '';
  position: absolute;
  background: #13a874;
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease;
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-transform: scaleX(0);
  transform: scaleX(0)
}

.header .nav-link:not(.active):hover:after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0
}

.header .dropdown:hover .nav-link:after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0
}

.header .dropdown-toggle {
  padding-right: 25px!important
}

.header .dropdown-toggle:before {
  top: 50%;
  right: 0;
  content: "\f078";
  font-weight: 700;
  font-size: 12px;
  position: absolute;
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  -webkit-transition: all .25s ease;
  transition: all .25s ease;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.header .dropdown-toggle[aria-expanded=true]:before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  top: 20px
}

.header .dropdown-toggle:after {
  content: "";
  border: none;
  margin-left: 0;
  display: inline-block;
  vertical-align: initial
}



.header .navbar-toggler .togler-icon-inner span {
  height: 3px;
  margin: 5px;
  width: 30px;
  display: block;
  background: #fff;
  -webkit-transition: all .3s ease;
  transition: all .3s ease
}

.header .navbar-toggler .togler-icon-inner span:nth-child(2) {
  margin-left: auto;
  width: 20px!important
}

.header .navbar-toggler .togler-icon-inner span:nth-child(3) {
  margin-bottom: 0
}

.header .navbar-toggler[aria-expanded=true] .togler-icon-inner span:nth-child(1) {
  -webkit-transform: rotate(-45deg) translate(-5px,6px);
  transform: rotate(-45deg) translate(-5px,6px)
}

.header .navbar-toggler[aria-expanded=true] .togler-icon-inner span:nth-child(2) {
  opacity: 0
}

.header .navbar-toggler[aria-expanded=true] .togler-icon-inner span:nth-child(3) {
  -webkit-transform: rotate(45deg) translate(-5px,-6px);
  transform: rotate(45deg) translate(-5px,-6px)
}

.header.header-shrink {
  padding: 0;
  background: #0032b5;
  -webkit-animation: stickyMenu .7s ease-in-out;
  animation: stickyMenu .7s ease-in-out
}

.header.header-shrink .ss4-phone {
  padding: 0 0 0 25%;
  position: absolute;
  font-size: 16px;
  font-weight: 600;
  color: #011a52
}

.header.header-shrink .ss4-phone .ss4no {
  color: #ffae00
}

.header.header-shrink .ss4-mail {
  padding: 0 0 0 22%;
  position: absolute;
  font-size: 16px;
  font-weight: 600;
  color: #fff
}

.header.header-shrink .ss4-mail .ss4id {
  color: #ffae00
}

@media only screen and (max-width: 575.98px) {
  .header.header-shrink .ss4-phone {
      display:none
  }

  .header.header-shrink .ss4-phone .ss4no {
      display: none
  }

  .header.header-shrink .ss4-mail {
      display: none
  }

  .header.header-shrink .ss4-mail .ss4id {
      display: none
  }
}

.header.header-shrink .nav-link {
  color: #fff
}

.header.header-shrink .togler-icon-inner span {
  background: #000
}

.header .login-btn-mobile,.header .signup-btn-mobile {
  display: none
}

.header .navbar-brand {
  margin-right: 0;
  padding: 0;
  z-index: 1000
}

.header .navbar-brand img {
  display: none
}

.header .navbar-brand img.logo-transparent {
  display: block
}

@media screen and (max-width: 991.98px) {
  .header {
      padding:0
  }

  .header .container,.header ul li {
      padding: 0;
      max-width: 100%
  }

  .header .navbar-brand {
      padding: 10px 0 10px 15px
  }

  .header .main-menu {
      background: #03c
  }

  .header .main-menu .navbar-nav {
      max-height: 400px;
      overflow: hidden;
      overflow-y: auto
  }

  .header .main-menu .navbar-nav .nav-item .nav-link:after {
      display: none
  }

  .header .main-menu .navbar-nav .nav-item .nav-link:not(.active):hover {
      background: #13a874;
      color: #fff!important;
      border-color: #13a874
  }

  .header .main-menu .navbar-nav .nav-item .nav-link.active {
      background: #13a874;
      color: #fff!important;
      border-color: #13a874
  }

  .header .main-menu .navbar-nav .nav-item .nav-link.dropdown-toggle::before {
      top: 27px;
      right: 20px
  }

  .header .main-menu .navbar-nav .nav-item .nav-link.dropdown-toggle[aria-expanded=true]:before {
      top: 11px;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg)
  }

  .header .main-menu .navbar-nav .nav-item:last-child .nav-link {
      border-bottom: 0
  }

  .header .main-menu .navbar-nav .nav-item .dropdown-menu {
      background: rgba(19,168,116,.1)
  }

  .header .main-menu .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover {
      background: #13a874
  }

  .header .login-btn-mobile,.header .signup-btn-mobile {
      top: 32px;
      border: none;
      color: #fff;
      font-size: 16px;
      display: block;
      position: absolute;
      background: 0 0
  }

  .header .signup-btn-mobile {
      right: 65px
  }

  .header .login-btn-mobile {
      right: 3rem
  }

  .header .login-btn-resp,.header .signup-btn-resp {
      display: none
  }

  .header.header-shrink .login-btn-mobile,.header.header-shrink .signup-btn-mobile {
      color: #000
  }
}

@media screen and (min-width: 992px) {
  .dropdown-menu {
      left:0;
      border: 0;
      top: 80%;
      z-index: 15;
      opacity: 0;
      color: #000;
      visibility: hidden;
      display: initial;
      min-width: 250px;
      border-radius: 0;
      position: absolute;
      -webkit-transition: all .5s ease;
      transition: all .5s ease
  }

  .dropdown-menu.show {
      opacity: 1;
      top: 100%;
      visibility: visible
  }
}


.down-scroll {
  left: 50%;
  width: 48px;
  height: 48px;
  z-index: 15;
  bottom: 10px;
  cursor: pointer;
  font-size: 16px;
  line-height: 48px;
  border-radius: 5px;
  position: absolute;
  text-align: center;
  color: #fff!important;
  background: #13a874;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.services-item {
  padding: 30px;
  position: relative;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 30px
}

.services-item .services-icon {
  height: 80px;
  width: 80px;
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
  font-size: 40px;
  line-height: 80px;
  border-radius: 5px;
  display: inline-block;
  background: #03c;
}

.services-item .services-link {
  font-size: 14px;
  color: #fff!important;
  padding: 10px 20px;
  border-radius: 5px;
  display: inline-block;
  background: #13a874;
  -webkit-transition: all .25s linear;
  transition: all .25s linear;
}

.services-item .services-link:hover {
  color: #fff!important;
  background: #109164;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px)
}

.services-item .services-body h5 {
  margin-bottom: 10px
}

.services-item .services-body p {
  margin-bottom: 20px
}

.services-page .services-item .services-body p {
  margin-bottom: 0
}

.about-col-left,.about-col-right {
  margin-bottom: 30px
}

.about-col-right .about-list {
  margin: 0 0 40px 0
}

.about-col-right .about-list ul li {
  margin-top: 15px;
  font-weight: 500;
  color: #000
}

.about-col-right .about-list ul li:first-child {
  margin-top: 0
}

.about-col-right .about-list ul li i {
  margin-right: 15px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  display: inline-block;
  border-radius: 5px;
  font-size: 10px;
  color: #fff!important;
  background: #03c;
}

.about-col-right .about-inner h3 {
  margin-bottom: 20px
}

.about-col-right .about-inner p {
  margin-bottom: 30px
}

@media only screen and (max-width: 767.98px) {
  .about-inner {
      margin-top:50px
  }
}

.features-list {
  display: inline-block
}

.features-list li {
  margin-top: 30px;
  border-radius: 5px;
  padding: 30px;
  -webkit-transition: all .25s linear;
  transition: all .25s linear;
}

.features-list li:first-child {
  margin-top: 0
}

.features-list li i {
  display: inline-block;
  margin-right: 20px;
  font-size: 35px;
  width: 80px;
  height: 80px;
  color: #fff;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 80px;
  flex: 0 0 80px;
  line-height: 80px;
  border-radius: 5px;
  text-align: center;
  background: #03c;
}

.features-list li h6 {
  margin-bottom: 5px
}

.features-inner h3 {
  margin-bottom: 15px;
  font-size: 28px;
  font-weight: 700;
  color: #000;
  text-align: left;
}

.features-inner p {
  margin-bottom: 30px
}

@media only screen and (max-width: 991.98px) {
  .features-image-mb {
      margin-bottom:30px
  }

  .features-image-order {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2
  }
}

.features-row-mt {
  margin-top: 100px
}

@media only screen and (max-width: 767.98px) {
  .features-image-resp {
      margin-top:60px
  }

  .features-row-mt {
      margin-top: 60px
  }
}

.how-it-works-section {
  position: relative;
  background: #03c
}

.how-it-works-section .section-sub-title {
  opacity: .9;
  color: #fff
}

.how-it-works-section .section-title {
  color: #fff
}

.how-it-works-section .section-title span {
  color: #fff
}

.how-it-works-section .section-title:after,.how-it-works-section .section-title:before {
  background: #fff
}

.how-it-works-inner h3 {
  margin-bottom: 15px
}

.how-it-works-inner p {
  margin-bottom: 40px;
  opacity: .85
}

.how-it-work-video {
  position: relative
}

.how-it-work-video .how-it-works-video-btn {
  position: absolute;
  text-align: center;
  height: 80px;
  width: 80px;
  line-height: 80px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  border-radius: 50%;
  cursor: pointer;
  font-size: 40px;
  background: #13a874;
  color: #fff!important;
  -webkit-animation: shadowPulse 2s linear infinite;
  animation: shadowPulse 2s linear infinite;
}

.screenshots-slider {
  position: relative
}

.screenshots-slider .owl-item {
  opacity: .7;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  -webkit-transform: scale(.7,.7);
  transform: scale(.7,.7);
  border-radius: 10px!important
}

.screenshots-slider .owl-item img {
  border-radius: 10px!important
}

.screenshots-slider .active.center {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1)
}

.screenshots-slider.owl-theme .owl-nav button {
  top: 50%;
  height: 50px;
  width: 50px;
  font-size: 14px;
  position: absolute;
  display: inline-block;
  border-radius: 5px;
  color: #fff!important;
  background: #13a874;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all .25s linear;
  transition: all .25s linear;
}

.screenshots-slider.owl-theme .owl-nav button:hover {
  color: #fff!important;
  background: #109164
}

.screenshots-slider.owl-theme .owl-nav button.owl-next {
  right: -30px
}

.screenshots-slider.owl-theme .owl-nav button.owl-prev {
  left: -30px
}

@media only screen and (max-width: 767.98px) {
  .screenshots-slider {
      position:relative
  }

  .screenshots-slider .owl-item {
      opacity: .6;
      -webkit-transition: all .5s ease;
      transition: all .5s ease;
      -webkit-transform: scale(.8,.8);
      transform: scale(.8,.8);
      border-radius: 10px!important
  }

  .screenshots-slider .owl-item img {
      border-radius: 10px!important
  }

  .screenshots-slider.owl-theme .owl-nav button.owl-next {
      right: 15px
  }

  .screenshots-slider.owl-theme .owl-nav button.owl-prev {
      left: 15px
  }
}

.price-toggle-wrap {
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 60px;
  display: inline-block;
  margin: 0 auto 60px auto;
}

.price-toggle-wrap a {
  float: left;
  color: #03c;
  border-radius: 5px;
  margin-right: 10px;
  padding: 20px 50px;
  position: relative;
  overflow: hidden;
  background: rgba(0,51,204,.1)
}

.price-toggle-wrap a.active {
  color: #fff;
  background: #03c
}

.pricing-tab-content {
  display: none
}

.pricing-tab-content.active {
  display: block
}

.pricing-tab-content .price-table {
  padding: 40px 0 20px 0;
  border-radius: 5px;
  background: #fff;
  margin-bottom: 40px;
}

.pricing-tab-content .price-table .price-btn-wrap,.pricing-tab-content .price-table .price-header {
  padding: 0 30px
}

.pricing-tab-content .price-table .price-header h4 {
  font-size: 1.4rem;
  margin-bottom: 7px;
  text-transform: capitalize
}

.pricing-tab-content .price-table .price-value h5 {
  font-size: 1.7rem
}

.pricing-tab-content .price-table .price-value h5 sub {
  bottom: 0
}

.pricing-tab-content .price-table .price-btn-wrap {
  text-align: center
}

.pricing-tab-content .price-table .price-icon span {
  height: 80px;
  width: 80px;
  color: #fff;
  display: block;
  font-size: 40px;
  border-radius: 5px;
  line-height: 80px;
  text-align: center;
  background: #03c;
}

.pricing-tab-content .price-table .price-body {
  padding: 30px;
  margin: 25px 0;
  border-top: 1px solid rgba(0,0,0,.062);
  border-bottom: 1px solid rgba(0,0,0,.062)
}

.pricing-tab-content .price-table .price-body ul {
  text-align: left
}

.pricing-tab-content .price-table .price-body ul li {
  margin-bottom: 15px
}

.pricing-tab-content .price-table .price-body ul li:before {
  content: "\f0da";
  font-weight: 600;
  margin-right: 15px;
  color: #13a874;
  font-family: "Font Awesome 5 Free"
}

.pricing-tab-content .price-table .price-body ul li:after {
  content: "\f00c";
  float: right;
  font-weight: 600;
  color: #13a874;
  font-family: "Font Awesome 5 Free"
}

.pricing-tab-content .price-table .price-body ul li:last-child {
  margin-bottom: 0
}

.pricing-tab-content .price-table .default-btn {
  display: block
}

.pricing-tab-content .price-table-active .price-table {
  background: #03c;
}

.pricing-tab-content .price-table-active .price-table .price-icon span {
  color: #03c;
  background: #fff
}

.pricing-tab-content .price-table-active .price-table h4,.pricing-tab-content .price-table-active .price-table h5,.pricing-tab-content .price-table-active .price-table li,.pricing-tab-content .price-table-active .price-table li::after,.pricing-tab-content .price-table-active .price-table li::before {
  color: #fff!important
}

.pricing-tab-content .price-table-active .price-table .price-body {
  border-top: 1px solid rgba(255,255,255,.2);
  border-bottom: 1px solid rgba(255,255,255,.2)
}

.pricing-tab-content .price-table-active .default-btn {
  padding: 18px 40px;
  background: 0 0;
  border: 2px solid #fff
}

.pricing-tab-content .price-table-active .default-btn:hover {
  background: #fff;
  color: #03c!important
}

@media only screen and (max-width: 991.98px) {
  .price-table-margin {
      margin-bottom:50px
  }
}

@media only screen and (max-width: 320px) {
  .price-toggle-wrap>a {
      padding:20px 30px
  }
}

.testimonial-section {
  position: relative;
  background: #03c;
  .no-js .owl-carousel, .owl-carousel.owl-loaded {
    display: block;
  }
  .owl-carousel {
    width: 100%;
    z-index: 1;
  }
  .owl-carousel, .owl-carousel .owl-item {
    position: relative;
  }
}

.testimonial-section .section-sub-title {
  opacity: .9;
}

.testimonial-section .section-title:after,.testimonial-section .section-title:before {
  background: #fff;
}

.testimonial-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  background: #fff;
  padding: 10px 5px;
  border-radius: 4px;
}

.testimonial-item .testimonial-img {
  margin-right: 0px;
  margin-bottom: 30px;
}

.testimonial-item .testimonial-img img {
  border-radius: 5px;
  width: inherit!important;
  display: inline-block!important
}

.testimonial-item .testimonial-body h5 {
  margin-bottom: 10px
}

.testimonial-item .testimonial-body span {
  margin-bottom: 10px;
  display: block
}

.testimonial-item .testimonial-body .testimonial-rating {
  margin-bottom: 30px
}

.testimonial-item .testimonial-body .testimonial-rating i {
  color: #f90;
  font-size: 20px
}

.testimonial-item .testimonial-body p {
  font-style: italic;
  font-size: 1rem;
  line-height: 2;
}
@media only screen and (max-width: 767.98px) {
  .testimonial-item {
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      text-align: center;
  }

  .testimonial-item .testimonial-img {
      margin-right: 0;
      margin-bottom: 30px;
  }
}

.testimonial-section .testimonial-slider .owl-dots {
  margin-top: 60px!important
}

.testimonial-section .owl-theme .owl-dot span {
  width: 10px;
  height: 8px;
  opacity: .5;
  margin-right: 0;
  border-radius: inherit;
  border-color: #fff;
  background-size: cover;
  background: #fff;
  -webkit-transition: all .25s linear;
  transition: all .25s linear
}

.testimonial-section .owl-theme .owl-dot.active span {
  width: 20px;
  opacity: 1!important;
}

.counters {
  padding-top: 100px;
  padding-bottom: 50px;
  background: #EFF0F0;
}

.counters .counter-item-inner {
  padding: 30px;
  background: 0 0;
  position: relative;
  border-radius: 5px;
  margin: 0 0 30px 0;
}

.counters .counter-icon {
  position: relative;
  display: inline-block;
  font-size: 50px;
  width: 100px;
  height: 100px;
  line-height: 100px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 30px;
  color: #13a874
}

.counters .counter-icon:after,.counters .counter-icon:before {
  content: "";
  left: -20px;
  right: -20px;
  top: -20px;
  bottom: -20px;
  opacity: 0;
  display: block;
  position: absolute;
  border-radius: 50%;
  border: 1px solid #13a874;
  -webkit-animation: iconPulse 1s linear infinite;
  animation: iconPulse 1s linear infinite
}

.counters .counter-icon:after {
  -webkit-animation-delay: .5s;
  animation-delay: .5s
}

.counters .counter-body h2 {
  font-size: 38px!important;
  line-height: 1;
  margin-bottom: 10px
}


.team-card {
  position: relative;
  border-radius: 5px;
  margin-bottom: 30px;
  background: #fff;
  -webkit-transition: all .25s linear;
  transition: all .25s linear;
}

.team-card .team-img {
  position: relative;
  overflow: hidden;
  border-radius: 5px
}


.team-card .team-social {
  position: absolute;
  top: -100%;
  width: 100%;
  left: 0;
  height: 100%;
  z-index: 1;
  border-radius: 5px;
  display: none
}

.team-card .team-social li {
  display: inline-block
}

.team-card .team-social li a {
  color: #fff;
  border-radius: 5px;
  margin-right: 20px;
  display: inline-block;
  font-size: 30px;
  -webkit-transition: all .3s ease 0s;
  transition: all .3s ease 0s
}

.team-card .team-social li a:hover {
  color: #13a874
}

.team-card .team-social li:last-child a {
  margin-right: 0
}



.team-card:hover .team-img:after {
  bottom: 0
}

.team-card:hover .team-social {
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center
}

.team-card:hover .team-social a {
  animation: fadeInUp .6s ease-in-out forwards
}

.team-card .team-card-content {
  padding: 30px 15px
}

.team-card .team-card-content .title {
  font-size: 20px;
  text-transform: capitalize;
  margin: 0 0 15px 0;
  position: relative
}
.team-card-content p {
  font-size: 19px;
  font-weight: bold;
}

.team-card .team-card-content .desc {
  display: inline-block;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 14px;
  padding: 10px 20px;
  color: #fff!important;
  background: #03c;
  text-transform: capitalize;
}

.download-wrap {
  background: #03c
}

.download-section-inner h2 {
  color: #fff;
  margin-bottom: 15px
}

.download-section-inner p {
  opacity: .9;
  color: #fff;
  margin-bottom: 20px
}

.download-buttons {
  margin-top: 40px
}

.download-buttons .download-btn {
  display: inline-block;
  overflow: hidden;
  padding: 20px 30px;
  max-width: 200px;
  border-radius: 5px;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 500;
  position: relative;
  text-align: left;
  margin-right: 15px;
  background: 0 0;
  text-transform: uppercase;
  color: #fff!important;
  -webkit-transition: all .25s linear;
  transition: all .25s linear;
}

.download-buttons .download-btn:hover {
  background: #fff;
  color: #03c!important
}

.download-buttons .download-btn:last-child {
  margin-right: 0
}

.download-buttons .download-btn i {
  top: 50%;
  left: 20px;
  font-size: 30px;
  position: absolute;
  display: inline-block;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.download-buttons .download-btn span {
  display: block;
  margin-left: 35px
}

.download-stars {
  position: relative
}

.download-stars .download-ratings {
  display: inline-block;
  position: relative;
  padding: 10px 20px 10px 0
}

.download-stars .download-ratings i {
  color: #fdd55e;
  margin-right: 4px
}

.download-stars .download-ratings i:last-child {
  margin-right: 0
}

.download-stars .download-ratings span {
  color: #fff;
  font-weight: 600;
  font-size: 3rem;
  margin-right: 10px
}

.download-stars .download-rate-count {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  color: #000;
  position: relative;
}

.download-stars .download-rate-count:before {
  top: 7px;
  left: -28px;
  content: "";
  border: 15px solid;
  border-radius: 5px;
  position: absolute;
  border-color: transparent #fff transparent transparent
}

@media only screen and (max-width: 991.98px) {
  .download-section-inner {
      text-align:center
  }

  .download-image {
      display: none
  }

  .download-section-inner .download-buttons,.download-stars {
      -webkit-box-pack: center!important;
      -ms-flex-pack: center!important;
      justify-content: center!important
  }
}

@media only screen and (max-width: 575.98px) {
  .download-section-inner .download-buttons {
      text-align:center;
      display: block!important
  }

  .download-section-inner .download-buttons .download-btn {
      display: block;
      margin: 0 auto 15px auto
  }

  .download-section-inner .download-buttons .download-btn:nth-child(2) {
      margin-bottom: 0
  }
}

.blog-item {
  border-radius: 5px;
  margin-bottom: 30px;
  position: relative;
  background: #fff;
  -webkit-transition: all .25s linear;
  transition: all .25s linear;
}


.blog-item .blog-item-inner {
  padding: 30px
}

.blog-item .blog-item-inner .blog-link {
  font-size: 14px;
  color: #fff!important;
  padding: 10px 20px;
  border-radius: 5px;
  display: inline-block;
  background: #13a874;
  -webkit-transition: all .25s linear;
  transition: all .25s linear;
}

.blog-item .blog-item-inner .blog-link:hover {
  color: #fff!important;
  background: #109164;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px)
}

.blog-item .blog-item-inner .blog-body a:hover h6 {
  color: #03c
}

.blog-item .blog-item-inner .blog-body a h6 {
  margin-bottom: 10px
}

.blog-item .blog-item-inner .blog-body p {
  margin-bottom: 20px;
  display: block
}

.blog-item .blog-item-inner .blog-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px
}

.blog-item .blog-item-inner .blog-meta a {
  font-size: 15px;
  margin-bottom: 0;
  margin-right: 10px;
  -webkit-transition: all .25s linear;
  transition: all .25s linear
}

.blog-item .blog-item-inner .blog-meta a:hover {
  color: #03c
}

.blog-item .blog-item-inner .blog-meta a i {
  color: #03c;
  margin-right: 5px
}

.blog-item .blog-item-inner .blog-meta a:last-child {
  margin-right: 0
}

.blog-item .blog-date {
  position: absolute;
  top: -10px;
  left: 30px;
  text-align: center;
  background: #03c;
  border-radius: 0 5px 5px 5px;
  min-width: 80px
}

.blog-item .blog-date::after {
  top: 6px;
  left: -14px;
  z-index: -22;
  content: "";
  position: absolute;
  border: 15px solid;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-color: transparent transparent transparent #03c
}

.blog-item .blog-date .blog-day {
  padding: 10px 15px;
  display: block;
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  font-family: Poppins,sans-serif
}

.blog-item .blog-date .blog-month {
  padding: 10px 20px;
  display: block;
  font-size: 14px;
  color: #fff;
  background: rgba(255,255,255,.1);
  border-radius: 0 0 5px 5px
}

.blog-item .blog-img {
  border-radius: 5px;
  position: relative;
  overflow: hidden
}

.blog-item .blog-img:hover img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2)
}

.blog-item .blog-img img {
  border-radius: 5px;
  -webkit-transition: all .5s ease;
  transition: all .5s ease
}

.newsletter {
  background: #03c
}

.newsletter-wrap .newsletter-title {
  margin-bottom: 60px
}

.newsletter-wrap .newsletter-form-group {
  position: relative;
  overflow: hidden
}

.newsletter-wrap .newsletter-form-group .newsletter-form-control {
  width: 100%;
  height: 80px;
  border: none;
  display: block;
  border-radius: 5px;
  padding: 0 80px 0 55px
}

.newsletter-wrap .newsletter-form-group .newsletter-form-btn {
  top: 50%;
  right: 10px;
  width: 60px;
  height: 60px;
  color: #fff;
  border-radius: 5px;
  position: absolute;
  background: #13a874;
  -webkit-transition: all .25s linear;
  transition: all .25s linear
}

.newsletter-wrap .newsletter-form-group .newsletter-form-btn:hover {
  color: #fff!important;
  background: #109164
}

.newsletter-wrap .newsletter-form-group .newsletter-form-btn,.newsletter-wrap .newsletter-form-group span {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.newsletter-wrap .newsletter-form-group span {
  top: 50%;
  left: 18.5px;
  opacity: .8;
  font-size: 18px;
  position: absolute
}

#faq-tab-accordion .card {
  border: 0
}

#faq-tab-accordion .card .card-header {
  padding: 15px;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
  border-radius: 5px;
  background: #fff;
  -webkit-transition: all .25s linear;
  transition: all .25s linear;
  background-size: 200% auto;
}

#faq-tab-accordion .card .card-header a {
  color: #000;
  font-weight: 600
}

#faq-tab-accordion .card .card-header h6 {
  font-size: 1.125rem
}

#faq-tab-accordion .card .card-header h6 span {
  height: 50px;
  width: 50px;
  color: #03c;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 1.125rem;
  display: inline-block;
  background: rgba(0,51,204,.1)
}

#faq-tab-accordion .card .card-header[aria-expanded=true] {
  background: #03c;
  border-bottom-color: transparent
}

#faq-tab-accordion .card .card-header[aria-expanded=true] .card-toggle-btn:after {
  content: "\f068";
  background: #fff;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

#faq-tab-accordion .card .card-header[aria-expanded=true] h6 {
  color: #fff
}

#faq-tab-accordion .card .card-header[aria-expanded=true] h6 span {
  background: #fff
}

#faq-tab-accordion .card .card-header .card-toggle-btn:after {
  height: 40px;
  width: 40px;
  color: #03c;
  content: "\f067";
  font-weight: 600;
  margin-left: 10px;
  display: inline-block;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  font-family: "Font Awesome 5 Free";
  background: rgba(0,51,204,.1);
  -webkit-transition: all .25s linear;
  transition: all .25s linear
}

.faq-form-wrap {
  padding: 0 15px
}

.faq-form-wrap .faq-form-title {
  margin-bottom: 30px
}

.faq-form-wrap .faq-form-control {
  border: none;
  width: 100%;
  color: #000;
  outline: 0;
  padding: 18px 20px;
  font-size: .9rem;
  border-radius: 5px;
  margin-bottom: 30px;
  -webkit-transition: all .25s ease;
  transition: all .25s ease;
}



@media only screen and (max-width: 991.98px) {
  .faq-form-margin {
      margin-top:50px
  }
}

.form-controlss {
  height: 35px;
  outline: 0;
  width: 100%;
  border: 1px solid #e7e7e7;
  color: #000;
  padding: 0 20px;
  border-radius: 4px;
  margin-bottom: 0;
  -webkit-transition: all .25s ease;
  transition: all .25s ease;
}

.form-controlss:focus {
  outline: 0;
  border: 3px solid #bee0ff;
  border-radius: 6px;
}

.file-input .form-controlss {
  height: auto;
  padding-top: 0
}

textarea.form-controlss {
  height: auto;
  padding-top: 5px
}

.form-control {
  height: 60px;
  outline: 0;
  width: 100%;
  border: none;
  color: #000;
  padding: 0 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  -webkit-transition: all .25s ease;
  transition: all .25s ease;
}

.form-control:focus {
  outline: 0;
  border: none;
}

textarea.form-control {
  height: auto;
  padding-top: 5px
}

.contact-alert .email-invalid span,.contact-alert .empty-form span,.contact-alert .success-form span {
  display: block;
  padding: 20px;
  margin-bottom: 10px
}

.contact-alert .email-invalid span,.contact-alert .empty-form span {
  background: #f8d7da;
  color: #721c24
}

.contant-info-wrap .contact-info-item {
  border-radius: 5px;
  padding: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
  -webkit-transition: all .25s linear;
  transition: all .25s linear;
}

.contant-info-wrap .contact-info-item i {
  display: inline-block;
  margin-right: 20px;
  font-size: 35px;
  width: 80px;
  height: 80px;
  color: #fff;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 80px;
  flex: 0 0 80px;
  line-height: 80px;
  border-radius: 5px;
  text-align: center;
  background: #03c;
}

.contant-info-wrap .contact-info-item h6 {
  margin-bottom: 5px
}

.contant-info-wrap .contact-info-item span {
  display: block
}

.contact-social-links {
  border-radius: 5px;
  padding: 30px 20px 20px 30px;
  margin-bottom: 30px;
  -webkit-transition: all .25s linear;
  transition: all .25s linear;
}

.contact-social-links ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.contact-social-links ul li {
  margin-right: 10px;
  margin-bottom: 10px
}

.contact-social-links ul li a i {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #fff;
  font-size: 26px;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background: #03c;
  -webkit-transition: all .25s linear;
  transition: all .25s linear;
}

.contact-social-links ul li a:hover i {
  color: #fff!important;
  background: #002db3;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px)
}

.footer {
  background-image: url("/bg-footer.png");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}

.footer .footer-top {
  padding: 50px 0 30px 0
}

.footer .footer-top .footer-title {
  font-weight: 600;
  color: #fff;
  margin-bottom: 30px
}


.footer li {
  margin-top: 10px;
  color: #fff
}

.footer li:first-child {
  margin-top: 0
}

.footer li a {
  opacity: .7;
  color: #fff
}

.footer .footer-links li:before {
  color: #13a874;
  font-weight: 600;
  content: "\f101";
  margin-right: 10px;
  vertical-align: top;
  display: inline-block;
  font-family: "Font Awesome 5 Free"
}

.footer-widget {
  margin-bottom: 30px
}

.footer-widget .footer-box .contact-info-footer {
  margin-top: 10px
}

.footer-widget .footer-box .contact-info-footer li {
  color: rgba(255,255,255,.7)
}

.footer-widget .footer-box .contact-info-footer li i {
  margin-right: 15px;
  color: #13a874
}

.footer-widget .footer-box .footer-links li:hover {
  opacity: 1
}

.footer-widget .footer-box .footer-links li:hover a {
  text-decoration: underline;
  opacity: 1
}

.footer-widget .footer-link a {
  font-size: 13px;
  color: #fff
}

.footer-widget .footer-box p {
  color: #fff;
  opacity: .7
}

.footer-widget .footer-box p i {
  color: #fff
}

.footer-widget .footer-box .footer-social-links {
  margin-top: 10px
}

.footer-widget .footer-box .footer-social-links a {
  height: 42px;
  width: 42px;
  color: #fff;
  margin-right: 10px;
  line-height: 42px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  -webkit-transition: all .25s ease;
  transition: all .25s ease;
  background: rgba(255,255,255,.05)
}

.footer-widget .footer-box .footer-social-links a:last-child {
  margin-right: 0
}

.footer-widget .footer-box .footer-social-links a:hover {
  background: #13a874
}

.copyright {
  opacity: .8;
  color: #fff;
  padding: 25px 0;
  background: rgba(255,255,255,.05)
}

.breadcrumb-section {
  padding: 30px 0;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center
}

.breadcrumb-section .breadcrumb-content {
  position: relative;
  z-index: 77
}

.breadcrumb-section .breadcrumb-content .bread-crumb-title {
  font-size: 2.2rem;
  color: #fff
}

.breadcrumb-section .breadcrumb-content .breadcrumb-link {
  margin-top: 90px
}

.breadcrumb-section .breadcrumb-content .breadcrumb-link li {
  color: #fff;
  display: inline-block
}

.breadcrumb-section .breadcrumb-content .breadcrumb-link li.active:before {
  content: "\f30b";
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
  font-weight: 600;
  color: #38e9ab;
  font-family: "Font Awesome 5 Free"
}

.signup-form-wrap {
  margin-top: 0px;
  margin-bottom: 20px;
  -webkit-transition: all .25s linear;
  transition: all .25s linear;
}

.signup-form-wrap .signup-form-header {
  padding: 20px;
  color: #fff;
  text-align: center;
  background: #ff2f00;
  border-radius: 5px 5px 0 0
}

.signup-form-wrap .signup-form-header .signup-title {
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600
}

.signup-form-wrap .signup-form-inner {
  padding: 0 20px 0 20px;
  text-align: center;
  background: #fff;
  border-radius: 5px 5px
}

.signup-form-wrap .signup-form-inner .signup-form-group {
  position: relative;
  overflow: hidden
}

.signup-form-wrap .signup-form-inner .signup-form-group>span {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 20px;
  color: #03c;
  border-radius: 0 5px 5px 0
}

.signup-form-wrap .signup-form-inner .signup-form-group .signup-form-control {
  height: 40px;
  border: 1px solid #000;
  width: 100%;
  outline: 0;
  font-size: .9rem;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
  padding: 0 50px 0 10px;
  -webkit-transition: all .25s ease;
  transition: all .25s ease;
}

.signup-form-wrap .signup-form-inner .signup-form-group .signup-form-control:focus {
  outline: 0;
  border: none;
  color: #000;
}

.signup-form-wrap .signup-form-inner .signup-form-btn {
  width: 90%;
  border: none;
  outline: 0;
  display: block;
  border-radius: 5px;
  text-transform: capitalize;
  color: #fff!important;
  background: #118d00;
  font-weight: 600;
  -webkit-transition: all .25s linear;
  transition: all .25s linear;
  margin: auto
}

.signup-form-wrap .signup-form-inner .signup-form-btn:hover {
  color: #fff!important;
  background: #002db3;
}

.signup-form-wrap .signup-form-inner .custom-control {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: auto;
  line-height: 20px;
  margin-bottom: 2px
}

.signup-form-wrap .signup-form-inner .custom-control .custom-control-label {
  margin-left: 15px;
  padding-left: 10px;
  line-height: 20px;
  font-size: 13px;
  color: #000
}

.signup-form-wrap .signup-form-inner .custom-control .custom-control-labels {
  padding-left: 5px;
  padding-top: 5px;
  line-height: 18px;
  font-size: 13px;
  color: #002fb9
}

.signup-form-wrap .signup-form-inner .custom-control .custom-control-label:before {
  background: #fff;
  border: 1px solid #03c;
}

.signup-form-wrap .signup-form-inner .custom-control .custom-control-input:checked~.custom-control-label:before {
  color: #fff;
  border-color: #03c;
  background: #03c
}

input.invalid {
  background-color: #fdd
}

.tab {
  display: none
}

button {
  background-color: #4caf50;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 17px;
  cursor: pointer
}

button:hover {
  opacity: .8
}

#prevBtn {
  background-color: #bbb
}

.inputGroup {
  background-color: #fff;
  display: block;
  position: relative
}

.inputGroup label {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 30px;
  width: 100%;
  display: block;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: #3c454c;
  background-color: #efefef;
  cursor: pointer;
  position: relative;
  z-index: 2;
  -webkit-transition: color .2s ease-in;
  transition: color .2s ease-in;
  overflow: hidden
}

.inputGroup label span {
  padding: 5px 15px 10px 50px;
  width: 100%;
  display: inline-block;
  text-align: left;
  font-size: 14px;
  font-weight: lighter;
  color: #3c454c;
  cursor: pointer;
  position: relative;
  z-index: 2;
  -webkit-transition: color .2s ease-in;
  transition: color .2s ease-in;
  overflow: hidden
}

.inputGroup label:before {
  width: 100%;
  height: 10px;
  border-radius: 50%;
  content: '';
  background-color: #5562eb;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%,-50%) scale3d(1,1,1);
  transform: translate(-50%,-50%) scale3d(1,1,1);
  -webkit-transition: all .3s cubic-bezier(.4,0,.2,1);
  transition: all .3s cubic-bezier(.4,0,.2,1);
  opacity: 0;
  z-index: -1
}

.inputGroup label:after {
  width: 32px;
  height: 32px;
  content: '';
  border: 2px solid #d1d7dc;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 2px 3px;
  border-radius: 50%;
  z-index: 2;
  position: absolute;
  left: 30px;
  top: 55%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in
}

.inputGroup input:checked~label {
  color: #fff
}

.inputGroup input:checked~label span {
  color: #fff
}

.inputGroup input:checked~label:before {
  -webkit-transform: translate(-50%,-50%) scale3d(56,56,1);
  transform: translate(-50%,-50%) scale3d(56,56,1);
  opacity: 1
}

.inputGroup input:checked~label:after {
  background-color: #11e24c;
  border-color: #11e24c
}

.inputGroup input {
  width: 32px;
  height: 32px;
  -webkit-box-ordinal-group: 2;
  order: 1;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden
}

.signup-form-inner {
  height: 34rem
}

@media only screen and (max-width: 991.98px) {
  .signup-form-wrap {
      text-align:center;
      background-position: 100% 0
  }

  .signup-form-bg {
      padding-top: 150px;
      padding-bottom: 100px;
      height: auto!important
  }

  .signup-form-wrap {
      margin-top: 70px;
      margin-bottom: 20px
  }
}

@media only screen and (max-width: 575.98px) {
  .signup-form-bg {
      padding-top:150px;
      padding-bottom: 100px;
      height: auto!important
  }

  .signup-form-header,.signup-form-inner {
      padding: 15px
  }

  .signup-form-inner {
      height: 38rem
  }
}

.bg-jarallax-overlay {
  position: relative;
  background: 0 0
}

.bg-jarallax-overlay:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .8;
  background-position: center;
  background: #2f3542;
  background-size: 200% auto
}

.hero-slider-wrap .vegas-overlay {
  opacity: .8;
  background: #2f3542
}

#heroparticles {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%
}

.hero_video {
  width: 100%;
  position: relative;
  overflow: hidden;
  -o-object-fit: scale-down;
  object-fit: scale-down
}

.hero_video .hero-overlay {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 15;
  opacity: .8;
  position: absolute;
  background: #2f3542
}

.hero_video .video-content {
  z-index: 27;
  position: relative
}

.hero_video .video-scroll-btn {
  z-index: 32
}

#svg-pattern-1 {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: auto;
  pointer-events: none;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

#svg-pattern-2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  pointer-events: none
}

@media only screen and (min-width: 1200px) {
  #svg-pattern-2 {
      bottom:-40px!important
  }
}

#svg-pattern-3 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  pointer-events: none
}

#svg-pattern-4 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  pointer-events: none
}

@media only screen and (min-width: 1200px) {
  #svg-pattern-4 {
      bottom:-60px
  }
}

.glitch-img-banner .overlay-glitch {
  top: 0;
  left: 0;
  z-index: 45;
  width: 100%;
  height: 100%;
  opacity: .8;
  content: "";
  position: absolute;
  background: #2f3542
}

.glitch-img-banner .glitch-img {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover
}

.glitch-img-banner .container {
  position: relative;
  z-index: 56
}

.glitch-img-banner .down-scroll {
  z-index: 58
}


.demo-item {
  margin-bottom: 30px;
  position: relative;
  border-radius: 10px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.demo-item .demo-title {
  padding: 30px 0;
  display: block;
  text-align: center;
  color: #000;
  font-size: 1.2rem;
  font-weight: 600;
  background: #fff;
  text-transform: capitalize;
  border-radius: 0 0 10px 10px
}

.demo-item .demo-img {
  overflow: hidden;
  position: relative;
  border-radius: 10px 10px 0 0
}

.demo-item .demo-item-link {
  padding: 30px;
  display: block;
  font-weight: 600;
  font-size: 1.125rem;
  background: #fff;
  border-radius: 0 0 5px 5px;
  color: #000!important;
  -webkit-transition: all .25s ease;
  transition: all .25s ease
}

.demo-item:hover .demo-item-link {
  color: #fff!important;
  background: #03c
}

.blog-single img {
  border-radius: 5px
}

.blog-single .blog-post-desc>p {
  margin-bottom: 20px
}

.blog-single .blog-post-desc>p:last-child {
  margin-bottom: 30px
}

.blog-single .blog-post-desc .blog-single-meta {
  padding: 20px 0;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(0,0,0,.06)
}

.blog-single .blog-post-desc .blog-single-meta .blog-single-meta-box span {
  margin-right: 10px
}

.blog-single .blog-post-desc .blog-single-meta .blog-single-meta-box span:last-child {
  margin-right: 0
}

.blog-single .blog-post-desc .blog-single-meta .blog-single-meta-box span i {
  color: #03c;
  margin-right: 10px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  display: inline-block;
  background: rgba(0,51,204,.1);
  text-align: center;
  border-radius: 50%
}

.blog-single .blog-post-desc .blog-single-meta .blog-single-meta-box a {
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 50%;
  background: rgba(0,51,204,.1);
  color: #03c;
  text-align: center;
  margin-right: 5px
}

.blog-single .blog-post-desc .blog-single-meta .blog-single-meta-box a:hover {
  background: #03c;
  color: #fff!important
}

.blog-single .blog-post-desc .blog-title-h2 {
  margin-bottom: 15px
}

.blog-single .blog-post-quote blockquote {
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  border-left: 5px solid #03c;
}

.blog-single .blog-post-quote blockquote p {
  font-style: italic;
  font-size: 1.125rem
}

.blog-single .blog-post-quote blockquote p strong {
  margin-top: 10px;
  display: block;
  color: #000
}

.blog-single .blog-subheadline {
  margin: 30px 0
}

.blog-single .blog-subheadline span {
  margin-right: 15px
}

.blog-single .blog-title-subline {
  margin-bottom: 20px
}

.blog-post-sidebar .blog-widgets {
  margin-bottom: 30px
}

.blog-post-sidebar .blog-widgets .blog-search-form {
  width: 100%;
  border: none;
  color: #000
}

.blog-post-sidebar .blog-widgets .blog-search-btn {
  top: 0;
  right: 0;
  border: none;
  width: 60px;
  outline: 0;
  height: 100%;
  color: #fff;
  position: absolute;
  background: #03c;
  border-radius: 0 5px 5px 0
}

.blog-post-sidebar .blog-widgets .recent-post-item {
  margin-bottom: 14px
}

.blog-post-sidebar .blog-widgets .recent-post-item .recent-post-img {
  float: left
}

.blog-post-sidebar .blog-widgets .recent-post-item .recent-post-title {
  font-size: 1rem;
  margin-bottom: 8px;
  line-height: 1.5
}

.blog-post-sidebar .blog-widgets .recent-post-item .recent-post-title:hover {
  color: #03c
}

.blog-post-sidebar .blog-widgets .recent-post-item .recent-post-details a {
  color: #03c
}

.blog-post-sidebar .blog-widgets .recent-post-item .recent-post-date {
  font-size: 15px
}

.blog-post-sidebar .blog-widgets .recent-post-item .recent-post-date i {
  margin-right: 8px;
  color: #03c
}

.blog-post-sidebar .blog-widgets .blog-category-list li {
  padding: 12px 0;
  border-bottom: 1px solid rgba(0,0,0,.08)
}

.blog-post-sidebar .blog-widgets .blog-category-list li:hover a {
  color: #03c;
  text-decoration: underline
}

.blog-post-sidebar .blog-widgets .blog-category-list li a .category-count {
  float: right
}

.blog-post-sidebar .blog-widgets .blog-tags li {
  float: left
}

.blog-post-sidebar .blog-widgets .blog-tags li.active a {
  background: #03c;
  color: #fff
}

.blog-post-sidebar .blog-widgets .blog-tags li a {
  color: #000;
  margin: 3px;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  display: inline-block;
  -webkit-transition: all .25s linear;
  transition: all .25s linear;
}

.blog-post-sidebar .blog-widgets .blog-tags li a:hover {
  color: #fff;
  background: #03c
}

.blog-title {
  position: relative
}

.blog-title:after {
  z-index: 1;
  content: "";
  width: 50px;
  display: block;
  position: relative;
  margin: 20px auto 20px 0;
  border-bottom: 2px solid #03c
}

.blog-title:before {
  border-bottom: 2px solid rgba(0,0,0,.06);
  bottom: 0;
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  border-bottom: 2px solid rgba(0,0,0,.06)
}

.blog-title-margin {
  margin-bottom: 30px
}

@media only screen and (max-width: 991.98px) {
  .blog-sidebar-wrap {
      margin-top:50px
  }
}

@media only screen and (max-width: 575.98px) {
  .blog-single-meta {
      -webkit-box-orient:vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: start!important;
      -ms-flex-pack: start!important;
      justify-content: flex-start!important;
      -webkit-box-align: start!important;
      -ms-flex-align: start!important;
      align-items: flex-start!important
  }

  .blog-single-meta .blog-single-meta-box>span {
      display: block;
      margin-bottom: 10px
  }

  .sub-comment-item {
      margin-left: 0
  }
}

.complaint-desc p {
  color: #313131;
  font-size: 12px;
  text-align: left;
  line-height: 18px
}

img.complain-img {
  width: 100%;
  height: auto
}

.comment-wrapper .panel-body {
  max-height: 650px;
  overflow: auto
}

.comment-wrapper .media-list .media img {
  width: 64px;
  height: 64px;
  border: 2px solid #e5e7e8;
  margin: auto 10px!important
}

.comment-wrapper .media-list .media {
  border-bottom: 1px dashed #efefef;
  margin-bottom: 25px
}

.signup-form-wrap .signup-form-header-adv {
  padding: 20px;
  color: #fff;
  text-align: center;
  background: #2c7300;
  border-radius: 5px 5px 0 0
}

.team-card-content {
  height: 9rem;
  padding: 30px 15px!important
}


.services-item {
  height: 18rem
}


textarea#adv_desc {
  border: 1px solid #000
}

.glow {
  color: #fff;
  text-align: center;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate
}

@-webkit-keyframes glow {
  from {
      text-shadow: 0 0 10px #fff,0 0 20px #fff,0 0 30px #e60073,0 0 40px #e60073,0 0 50px #e60073,0 0 60px #e60073,0 0 70px #e60073
  }

  to {
      text-shadow: 0 0 20px #fff,0 0 30px #ff4da6,0 0 40px #ff4da6,0 0 50px #ff4da6,0 0 60px #ff4da6,0 0 70px #ff4da6,0 0 80px #ff4da6
  }
}

i.mdi.mdi-cellphone-text,i.mdi.mdi-email-outline,i.mdi.mdi-map-marker {
  animation: mymove 10s infinite
}


@keyframes mymove {
  12.5% {
      background-color: #900
  }

  25% {
      background-color: #903
  }

  37.5% {
      background-color: #906
  }

  50% {
      background-color: #909
  }

  62.5% {
      background-color: #90c
  }

  75% {
      background-color: #90f
  }
}

.testimonial-item {
  min-height: 450px!important
}

.complaint-list {
  background: #fff;
  padding: 2rem;
  overflow: hidden;
  border-radius: 15px
}

.complaint-list h1 {
  color: #0ab531;
  font-size: 1.2rem
}

.complaint-list p {
  color: #757575;
  line-height: 1.5rem;
  height: 3.5rem;
  overflow: hidden;
  font-size: 14px
}

.complaint-list span {
  color: #ffae00
}

.complaint-details span {
  color: #fff;
  margin: 0 .2rem;
  background: #47c5f5;
  padding: 2px 8px;
  border: none;
  border-radius: 5px;
  font-size: 12px
}

.row.city-list {
  border-bottom: 1px dotted brown
}

span.city {
  padding: 0 10px;
  font-size: 14px;
  text-align: left
}

.complaint-details i {
  margin-right: 3px
}

span.cases {
  background: #ffae00;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0 5px;
  font-size: 13px
}

.city-list-title h6 {
  text-align: center;
  color: #03c;
  font-size: 1.5rem
}

.city-list i {
  color: #ffae00;
  margin-right: 4px
}

.complaint-detail i {
  margin: 0 8px
}

.complaint-detail span {
  background: #018ca2;
  color: #fff;
  padding: .15rem .5rem;
  border-radius: 14px;
  font-size: 12px
}

.complaint-detail i {
  margin-left: 5px;
  margin-right: 8px
}

.share-complain span {
  color: #fff;
  padding: .5rem .8rem;
  border-radius: 6px;
}

.share-complain i {
  margin-right: 6px
}

.complaint-amount i {
  color: #000;
  margin-right: 5px
}

.lst {
  margin: 1rem 0;
  text-align: left;
  font-size: 12px;
  color: #757575
}

.pagination-area {
  text-align: center;
  color: #ffae00
}

i.fas.fa-angle-double-right {
  margin-left: 5px
}

.lst i {
  margin: .5rem .8rem
}

a.page-numbers {
  margin: 2rem
}

.row.city-list.py-2 {
  text-align: left;
  background: #fff;
  padding: 0 2rem
}

span.page-numbers.current {
  margin-right: 2rem
}

.txt {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical
}

div#home {
  background: #eff0f0
}


button.signup-form-btn.btn-md {
  border-radius: 30px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  background-color: #118c01;
}

@media (max-width: 480px) {
  .complaint-list p {
      height:3.5rem
  }
}
.item-mobile{
  display: none;
  position: relative;
}
.sub-menu-list{
  position: absolute;
  top: 0;
  // display: none;
}
@media (max-width: 1000px) {
  .menu-item{
    display: none;
  }
  .item-mobile{
    display: block;
    cursor: pointer;
  }
}

.pac-container {
  display: none;
}

@media only screen and (max-width: 991.98px) {
  body,html {
      font-size:14px
  }
}

@media only screen and (max-width: 575.98px) {
  .header .ss4-phone {
      display:none
  }

  .header .ss4-phone .ss4no {
      display: none
  }

  .header .ss4-mail {
      display: none
  }

  .header .ss4-mail .ss4id {
      display: none
  }
}

@media only screen and (max-width: 575.98px) {
  .header.header-shrink .ss4-phone {
      display:none
  }

  .header.header-shrink .ss4-phone .ss4no {
      display: none
  }

  .header.header-shrink .ss4-mail {
      display: none
  }

  .header.header-shrink .ss4-mail .ss4id {
      display: none
  }
}

@media screen and (max-width: 991.98px) {
  .header {
      padding:0
  }

  .header .container,.header ul li {
      padding: 0;
      max-width: 100%
  }

  .header .navbar-brand {
      padding: 10px 0 10px 15px
  }

  .header .main-menu {
      background: #03c
  }

  .header .main-menu .navbar-nav {
      max-height: 400px;
      overflow: hidden;
      overflow-y: auto
  }

  .header .main-menu .navbar-nav .nav-item .nav-link:after {
      display: none
  }

  .header .main-menu .navbar-nav .nav-item .nav-link:not(.active):hover {
      background: #13a874;
      color: #fff!important;
      border-color: #13a874
  }

  .header .main-menu .navbar-nav .nav-item .nav-link.active {
      background: #13a874;
      color: #fff!important;
      border-color: #13a874
  }

  .header .main-menu .navbar-nav .nav-item .nav-link.dropdown-toggle::before {
      top: 27px;
      right: 20px
  }

  .header .main-menu .navbar-nav .nav-item .nav-link.dropdown-toggle[aria-expanded=true]:before {
      top: 11px;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg)
  }

  .header .main-menu .navbar-nav .nav-item:last-child .nav-link {
      border-bottom: 0
  }

  .header .main-menu .navbar-nav .nav-item .dropdown-menu {
      -webkit-box-shadow: none;
      box-shadow: none;
      background: rgba(19,168,116,.1)
  }

  .header .main-menu .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover {
      background: #13a874
  }

  .header .login-btn-mobile,.header .signup-btn-mobile {
      top: 32px;
      border: none;
      color: #fff;
      font-size: 16px;
      display: block;
      position: absolute;
      background: 0 0
  }

  .header .signup-btn-mobile {
      right: 65px
  }

  .header .login-btn-mobile {
      right: 3rem
  }

  .header .login-btn-resp,.header .signup-btn-resp {
      display: none
  }

  .header.header-shrink .login-btn-mobile,.header.header-shrink .signup-btn-mobile {
      color: #000
  }
}

@media screen and (min-width: 992px) {
  .dropdown-menu {
      left:0;
      border: 0;
      top: 80%;
      z-index: 15;
      opacity: 0;
      color: #000;
      visibility: hidden;
      display: initial;
      min-width: 250px;
      border-radius: 0;
      position: absolute;
      -webkit-transition: all .5s ease;
      transition: all .5s ease
  }

  .dropdown-menu.show {
      opacity: 1;
      top: 100%;
      visibility: visible
  }
}

@media only screen and (max-width: 991.98px) {
  .hero-section,.hero-section-2,.parallax-bg {
      text-align:center;
      background-position: 100% 0
  }

  .hero-section {
      min-height: 100vh
  }

  .hero-section .hero-image {
      display: none
  }

  .hero-section .hero-section-subtitle {
      font-size: 1rem
  }

  .hero-section .container .row,.hero-section-2 .container .row,.parallax-bg .container .row {
      -webkit-box-pack: center!important;
      -ms-flex-pack: center!important;
      justify-content: center!important
  }
}

@media only screen and (max-width: 575.98px) {
  .hero-section-title {
      font-size:2rem
  }

  .hero-section .hero-section-title {
      margin-top: -30px;
      font-size: 2rem
  }

  .hero-section .hero-section-subtitle {
      margin-bottom: -30px;
      font-size: 2rem
  }

  section .button-group-hero {
      display: none!important
  }
}

@media only screen and (max-width: 767.98px) {
  .about-inner {
      margin-top:50px
  }
}

@media only screen and (max-width: 991.98px) {
  .features-image-mb {
      margin-bottom:30px
  }

  .features-image-order {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2
  }
}

@media only screen and (max-width: 767.98px) {
  .features-image-resp {
      margin-top:60px
  }

  .features-row-mt {
      margin-top: 60px
  }
}

@media only screen and (max-width: 767.98px) {
  .screenshots-slider {
      position:relative
  }

  .screenshots-slider .owl-item {
      opacity: .6;
      -webkit-transition: all .5s ease;
      transition: all .5s ease;
      -webkit-transform: scale(.8,.8);
      transform: scale(.8,.8);
      border-radius: 10px!important
  }

  .screenshots-slider .owl-item img {
      border-radius: 10px!important
  }

  .screenshots-slider.owl-theme .owl-nav button.owl-next {
      right: 15px
  }

  .screenshots-slider.owl-theme .owl-nav button.owl-prev {
      left: 15px
  }
}

@media only screen and (max-width: 991.98px) {
  .price-table-margin {
      margin-bottom:50px
  }
}

@media only screen and (max-width: 320px) {
  .price-toggle-wrap>a {
      padding:20px 30px
  }
}

@media only screen and (max-width: 767.98px) {
  .testimonial-item {
      display:-webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      text-align: center
  }

  .testimonial-item .testimonial-img {
      margin-right: 0;
      margin-bottom: 30px
  }
}

@media only screen and (max-width: 991.98px) {
  .download-section-inner {
      text-align:center
  }

  .download-image {
      display: none
  }

  .download-section-inner .download-buttons,.download-stars {
      -webkit-box-pack: center!important;
      -ms-flex-pack: center!important;
      justify-content: center!important
  }
}

@media only screen and (max-width: 575.98px) {
  .download-section-inner .download-buttons {
      text-align:center;
      display: block!important
  }

  .download-section-inner .download-buttons .download-btn {
      display: block;
      margin: 0 auto 15px auto
  }

  .download-section-inner .download-buttons .download-btn:nth-child(2) {
      margin-bottom: 0
  }
}

@media only screen and (max-width: 991.98px) {
  .faq-form-margin {
      margin-top:50px
  }
}

@media only screen and (max-width: 991.98px) {
  .signup-form-wrap {
      text-align:center;
      background-position: 100% 0
  }

  .signup-form-bg {
      padding-top: 150px;
      padding-bottom: 100px;
      height: auto!important
  }

  .signup-form-wrap {
      margin-top: 70px;
      margin-bottom: 20px
  }
}

@media only screen and (max-width: 575.98px) {
  .signup-form-bg {
      padding-top:150px;
      padding-bottom: 100px;
      height: auto!important
  }

  .signup-form-header,.signup-form-inner {
      padding: 15px
  }

  .signup-form-inner {
      height: 38rem
  }
}

@media only screen and (min-width: 1200px) {
  #svg-pattern-2 {
      bottom:-40px!important
  }
}

@media only screen and (min-width: 1200px) {
  #svg-pattern-4 {
      bottom:-60px
  }
}

@media screen and (max-width: 576px) {
  .hero-section .intro-hero-title {
      font-size:1.6rem
  }

  .hero-section .intro-hero-title span {
      font-size: 2.5rem
  }
}

@media only screen and (max-width: 991.98px) {
  .blog-sidebar-wrap {
      margin-top:50px
  }
}

@media only screen and (max-width: 575.98px) {
  .blog-single-meta {
      -webkit-box-orient:vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: start!important;
      -ms-flex-pack: start!important;
      justify-content: flex-start!important;
      -webkit-box-align: start!important;
      -ms-flex-align: start!important;
      align-items: flex-start!important
  }

  .blog-single-meta .blog-single-meta-box>span {
      display: block;
      margin-bottom: 10px
  }

  .sub-comment-item {
      margin-left: 0
  }
}

@media (max-width: 480px) {
  .complaint-list p {
      height:3.5rem
  }
}

.text-banner{
  color: var(--secondary-yellow-base, #ffd300);
  font-family: FCIconicRegular;
  letter-spacing: .15px;
  line-height: 26px;
  font-size: 20px;
  text-transform: uppercase;
}
.subtext-banner{
  color: #fff;
  font-family: FCIconicMedium;
  font-size: 50px;
  line-height: 78px;
  text-shadow: 0 4px 8px rgba(0, 0, 0, .5);
  margin-bottom: 0px;
}
.my-head-text{
  color: #ffd300;
  font-family: Grecko;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  text-align: center;
  font-size: 88px;
  line-height: 70px;
  text-shadow: 0 4px 8px rgba(0, 0, 0, .5);
}
.banner-item{
  display: grid;
  grid-template-columns: 1fr 3fr;
}
.bg-00264E {
  background-color: #00264e;
}
.banner-cib{
  max-width: 375px;
}
.logo-header{
  max-width: 50px;
}
.logo-header1{
  max-width: 56px;
}
.main-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu-item{
  font-size: 18px;
  letter-spacing: .4px;
  line-height: 26px;
  padding: 5px;
  color: #fff !important;
}
.menu-list{
  display: flex;
  gap: 20px;
  justify-content: space-between;
}
.header-nav{
  z-index: 40 !important;
}
.btn-header{
  background: rgba(0, 0, 0, 0.01);
  border: 2px solid rgb(242, 244, 247);
  backdrop-filter: blur(1px);
  padding: 8px 20px;
  text-align: center;
  border-radius: 8px;
  max-height: 56px;
  font-weight: bold;
  color: #fff !important;
  height: 100%;
  font-size: 18px;
}

.item-slider{
  margin: 25px 15px;
}
.HomeSlider {
  margin-bottom: var(--space_item_primary);
  .HomeSlider_item {
    padding: 6px;

    .HomeSlider_img {
      padding: 6px;
      height: auto;
      width: 100%;
      cursor: pointer;
      object-fit: cover;
      margin: auto;
      border-radius: 16px;
      aspect-ratio: var(--aspect_ratio_banner);
      overflow: hidden;
    }
  }
  .slick-list,
  .slick-slider,
  .slick-track {
    height: 100%;
  }

}
.slick-track {
  display: flex;
}
.slick-initialized{
  .slick-arrow{
    display: none !important;
  }
}
.card-slider {
  overflow: hidden;

  & .slick-list {
    width: 100%;
    overflow: visible;
    height: 100%;
    min-height: 400px;
  }

  & .slick-track {
    box-sizing: border-box;
  }

  & .group {
    background-color: #f9f9f9;
  }

  & .card {
    border-radius: 10px;
    background-color: #f9f9f9;
    min-height: 400px;
    height: 100%;
    overflow: hidden;
    padding: 10px;
  }
}

.slick-track .slick-slide.slick-active {
  padding-right: 9px;
  box-sizing: border-box;
  transition: all 0.3s;
}

.card .unsplash {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.one-slider{
  .slick-track{
    width: 100% !important;
  }
}
.video-news{
  width: 100%;
  min-height: 400px;
  height: 100%;
}
.img-fluid{
  width: 360px;
  height: 288px !important;
}
.team-img {
  width: 100%;
  height: 100%;
}
.text-header-title{
  padding: 8px 10px;
  background: #FFD300;
  color: #00264E;
  max-width: 80px;
  font-weight: bold;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
}
.team-top{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.news-top{
  display: flex;
  align-items: center;
  gap: 10px;
}
.team-title{
  color: #FFD300;
  border-right: 2px solid #c2c2c2;
}
.news-card {
  position: relative;
  border-radius: 5px;
  margin-bottom: 30px;
  background: #fff;
  -webkit-transition: all .25s linear;
  transition: all .25s linear;
}

.news-card .news-img {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  width: 360px;
  height: 360px;
  cursor: pointer;
  .img-fluid{
    width: 100%;
    height: 100% !important;
  }
}
.news-card-content{
  position: absolute;
  bottom: 0;
  padding: 15px;
  color: #fff;
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, .7));
  p{
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 5px;
  }
}
.news-title{
  padding: 5px 8px;
  background: #FFD300;
  color: #000;
  max-width: 100px;
  font-size: 13px;
  border-radius: 8px;
}


.team-card-main {
  position: relative;
  border-radius: 5px;
  margin-bottom: 30px;
  background: #fff;
  -webkit-transition: all .25s linear;
  transition: all .25s linear;
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 15px;
}

.team-card-main .team-img-main {
  position: relative;
  overflow: hidden;
  border-radius: 5px
}
.img-fluid-main{
  width: 100%;
  height: 100%;
}
.team-main{
  display: flex;
  gap: 10px;
}
.team-main-title{
  color: #FFD300;
  font-weight: bold;
}
.team-card-main-content{
  padding: 10px 0;
  p{
    font-size: 19px;
    font-weight: 600;
  }
}
@media (max-width: 968px) {
  .col-md-4 {
      flex: 0 0 50% !important;
      max-width: 50% !important;
  }
  .footer-main{
    grid-template-columns: 1fr 1fr !important;
  }
}
@media (max-width: 480px) {
  .col-md-4 {
      flex: 0 0 100% !important;
      max-width: 100% !important;
  }
  .team-card{
    text-align: center;
  }
  .news-card .news-img {
    width: 100%;
  }
  .team-card-main {
    grid-template-columns:unset
  }
}
.map-img{
  max-width: 100%;
}
.contact{
  padding: 80px !important;
  .title-contact{
    font-size: 56px;
    font-weight: 600;
    color: #fff;
  }
}
.icon-contact{
  max-width: 96px;
}
#contact{
  background: #101828;
}
a:hover{
  color: #fff !important;
}
.header-contact{
  color: #FFD300;
  font-size: 18px;
  margin-top: 16px;
}
.contact-item{
  padding-right: 4rem !important;
}
.font-16{
  font-size: 16px;
}
.footer-main{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  color: #fff;
  text-align: start !important;
  gap: 10px;
  border-bottom: 1px solid #1d2939;
  margin-bottom: 10px;
  p{
    font-size: 17px;
    cursor: pointer;
  }
  .footer-title{
    color: #FFD300 !important;
  }
}
.logo-footer{
  max-width: 100px;
}
.logo-footer1{
  max-width: 100px;
  margin-left: 10px;
}
.font-14{
  font-size: 14px;
}
.font-19{
  font-size: 19px;
}
.ConsumerComplaint{
  border: 1px solid;
  border-radius: 6px;
}
